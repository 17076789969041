<template>
  <b-card>
    <b-card-header>
      <b-card-title>Total de Compradores</b-card-title>
    </b-card-header>
    
    <b-card-body>
      <b-row>
        <b-col
          v-for="(estadoComprador, index) in listaEstadoCompradores"
          :key="index" xl="4" sm="6" class="my-2"
        >
          <template v-if="index === 0">
            <b-link :to=" { name: 'usuarios-comprador' } ">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar size="48" :variant="statusCompradorVariant(estadoComprador.descricao)">
                    <feather-icon size="24" :icon="statusCompradorIcon(estadoComprador.descricao)" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">{{ estadoComprador.quantidade }}</h4>
                  <b-card-text class="font-small-3 mb-0 text-dark">
                    {{ estadoComprador.descricao }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-link>
          </template>

          <template v-else>
            <b-media no-body>
              <b-media-aside>
                <b-avatar size="48" :variant="statusCompradorVariant(estadoComprador.descricao)">
                  <feather-icon size="24" :icon="statusCompradorIcon(estadoComprador.descricao)" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{ estadoComprador.quantidade }}</h4>
                <b-card-text class="font-small-3 mb-0 text-dark">
                  {{ estadoComprador.descricao }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </template>
        </b-col>
      </b-row>
    </b-card-body>

  </b-card>
</template>

<script>

import { 
  BCard, 
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCardText
 } from 'bootstrap-vue'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardText,
    FeatherIcon,
  },
  data() {
    return {
      listaEstadoCompradores: [],
    }
  },
  created() {
    this.buscarTodosCompradores();
  },
  methods: {
    statusCompradorVariant(descricao) {
      if (descricao === "Pendente de confirmação") return "light-warning";
      if (descricao === "Cadastro concluído") return "light-success";
      if (descricao === "Total") return "light-secondary";
      return "light-warning";
    },
    statusCompradorIcon(descricao) {
      if (descricao === "Pendente de confirmação") return "InfoIcon";
      if (descricao === "Cadastro concluído") return "CheckCircleIcon";
      if (descricao === "Total") return "SearchIcon";
      return "light-warning";
    },
    buscarTodosCompradores() {
      useJwt.gestaoBuscarTodosCompradores()
        .then(response => {
          this.listaEstadoCompradores = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }  
  },
}
</script>
