<template>
  <b-card>
    <b-card-header>
      <b-card-title>Informação de Hits</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col
          v-for="(estadoHit, index) in listaEstadoHits"
          :key="index" xl="3" sm="6" class="my-2"
        >
          <template v-if="index === 0">
            <b-link :to=" { name: 'aprovacoes-hit' } ">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar size="48" :variant="statusHitVariant(estadoHit.descricao)">
                    <feather-icon size="24" :icon="statusHitIcon(estadoHit.descricao)" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">{{ estadoHit.quantidade }}</h4>
                  <b-card-text class="font-small-3 mb-0 text-dark">
                    {{ estadoHit.descricao }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-link>
          </template>

          <template v-else>
            <b-media no-body>
              <b-media-aside>
                <b-avatar size="48" :variant="statusHitVariant(estadoHit.descricao)">
                  <feather-icon size="24" :icon="statusHitIcon(estadoHit.descricao)" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{ estadoHit.quantidade }}</h4>
                <b-card-text class="font-small-3 mb-0 text-dark">
                  {{ estadoHit.descricao }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </template>
        </b-col>
      </b-row>
    </b-card-body>
    
  </b-card>
</template>

<script>
import { 
  BCard, 
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCardText
 } from 'bootstrap-vue'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardText,
    FeatherIcon,
  },
  data() {
    return {
      listaEstadoHits: [],
    }
  },
  created() {
    this.buscarTodosHits()
  },
  methods: {
    statusHitVariant(descricao) {
      if (descricao === "Em análise") return "light-warning";
      if (descricao === "Aprovado") return "light-success";
      if (descricao === "Reprovação geral") return "light-danger";
      if (descricao === "Reprovação de informações") return "light-danger";
      if (descricao === "Reprovação de guia") return "light-danger";
      if (descricao === "Pendente de pagamento") return "light-warning";
      if (descricao === "Aguardando envio da guia") return "light-warning";
      if (descricao === "Total") return "light-secondary";
      return "light-warning";
    },
    statusHitIcon(descricao) {
      if (descricao === "Em análise") return "ClockIcon";
      if (descricao === "Aprovado") return "CheckCircleIcon";
      if (descricao === "Reprovação geral") return "XIcon";
      if (descricao === "Reprovação de informações") return "InfoIcon";
      if (descricao === "Reprovação de guia") return "MusicIcon";
      if (descricao === "Pendente de pagamento") return "DollarSignIcon";
      if (descricao === "Aguardando envio da guia") return "MusicIcon";
      if (descricao === "Total") return "SearchIcon";
      return "light-warning";
    },
    buscarTodosHits() {
      useJwt.gestaoBuscarTodosHits()
        .then(response => {
          this.listaEstadoHits = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }   
  },
}
</script>
