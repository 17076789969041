<template>
  <section id="dashboard-geral">
    
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <gestao-total-compositor />
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
        <gestao-total-comprador />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <gestao-total-hits />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import GestaoTotalCompositor from './GestaoTotalCompositor.vue'
import GestaoTotalComprador from './GestaoTotalComprador.vue'
import GestaoTotalHits from './GestaoTotalHits.vue'


export default {
  components: {
    BRow,
    BCol,

    GestaoTotalCompositor,
    GestaoTotalComprador,
    GestaoTotalHits
  },
}
</script>
